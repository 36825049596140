<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <b-overlay :show="loading">
            <b-row>
                <b-col lg="12" sm="12">
                    <CCard>
                        <CCardHeader>
                            <div class="row">
                                <div class="col-md-6">
                                    <strong>App Update Setting</strong>
                                </div>
                            </div>
                        </CCardHeader>
                        <CCardBody>
                            <div class='row'>
                                <b-col lg="12" sm="12">
                                        <ValidationObserver ref="form1"  v-slot="{ handleSubmit, reset }">
                                            <b-form  @submit.prevent="handleSubmit(register(update))" @reset.prevent="reset" >
                                               
                                                <div class='row'>
                                                    <b-col lg="3" sm="3">
                                                        <div>
                                                            <p class="m-0 mb-2" style="font-weight: bold"> Maintance Mode </p>
                                                            <b-form-checkbox v-model="update.maintance" size="md" name="check-button" switch>
                                                                <span v-if="update.maintance">ON</span>
                                                                <span v-else>OF</span>
                                                            </b-form-checkbox>
                                                        </div>
                                                    </b-col>
                                                    <b-col lg="3" sm="3">
                                                        <div>
                                                            <p class="m-0 mb-2" style="font-weight: bold">  Promotion ON OFF  </p>
                                                            <b-form-checkbox v-model="update.promotion_on_off" size="md" name="check-button" switch>
                                                                <span v-if="update.promotion_on_off">ON</span>
                                                                <span v-else>OF</span>
                                                            </b-form-checkbox>
                                                        </div>
                                                    </b-col>
                                                    <b-col lg="3" sm="3">
                                                        <div>
                                                            <p class="m-0 mb-2" style="font-weight: bold">  App ON OFF  </p>
                                                            <b-form-checkbox v-model="update.app_on_off" size="md" name="check-button" switch>
                                                                <span v-if="update.app_on_off">ON</span>
                                                                <span v-else>OF</span>
                                                            </b-form-checkbox>
                                                        </div>
                                                    </b-col>
                                                   
                                                  
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="App Download link" vid="dlink" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                            App Download link
                                                            </template>
                                                            <b-form-input
                                                                id="dlink"
                                                                v-model="update.dlink"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="App Download link 2" vid="admin_dlink" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                           App Download link 2
                                                            </template>
                                                            <b-form-input
                                                                id="admin_dlink"
                                                                v-model="update.admin_dlink"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Main App Version" vid="version" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                                Main App Version
                                                            </template>
                                                            <b-form-input
                                                                id="version"
                                                                v-model="update.version"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Telegram Link" vid="telegram" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                                Telegram Link 
                                                            </template>
                                                            <b-form-input
                                                                id="telegram"
                                                                v-model="update.telegram"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                </div>
                                                <div class="text-right">
                                                    <b-button type="submit" variant="primary" style="margin-top:40px" class="mr-2">Update</b-button>
                                                </div>
                                            </b-form>
                                        </ValidationObserver>
                                    
                                </b-col>                                
                            </div>
                        </CCardBody>
                    </CCard>
                </b-col>
                <b-col lg="12" sm="12">
                    <CCard>
                        <CCardHeader>
                            <div class="row">
                                <div class="col-md-6">
                                    <strong>Ads Setting</strong>
                                </div>
                            </div>
                        </CCardHeader>
                        <CCardBody>
                            <div class='row'>
                                <b-col lg="12" sm="12">
                                    <ValidationObserver ref="form2"  v-slot="{ handleSubmit, reset }">
                                        <b-form  @submit.prevent="handleSubmit(register(update))" @reset.prevent="reset" >
                                            <div class='row'>
                                                <b-col lg="12" sm="12">
                                                    <div>
                                                        <p class="m-0 mb-2" style="font-weight: bold"> Active Google Ads </p>
                                                        <b-form-checkbox v-model="update.active_google_ads" size="md" name="check-button" switch>
                                                            <span v-if="update.active_google_ads">ON</span>
                                                            <span v-else>OF</span>
                                                        </b-form-checkbox>
                                                    </div>
                                                </b-col>
                                                <b-col lg="6" sm="6">
                                                    <ValidationProvider name="Google Ads App ID" vid="g_ads_app_id" rules="required">
                                                        <b-form-group
                                                        class="row"
                                                        label-cols-sm="12"
                                                        label-for="formData"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            Google Ads App ID
                                                        </template>
                                                        <b-form-input
                                                            id="g_ads_app_id"
                                                            v-model="update.g_ads_app_id"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Google Rewarded Video Ads" vid="g_rewarded_video_ads" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                                Google Rewarded Video Ads
                                                            </template>
                                                            <b-form-input
                                                                id="g_rewarded_video_ads"
                                                                v-model="update.g_rewarded_video_ads"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Google Interstitial Id" vid="g_interstitial_id" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                                Google Interstitial Id
                                                            </template>
                                                            <b-form-input
                                                                id="g_interstitial_id"
                                                                v-model="update.g_interstitial_id"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Google Banner ID" vid="g_banner_id" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                                Google Banner ID
                                                            </template>
                                                            <b-form-input
                                                                id="g_banner_id"
                                                                v-model="update.g_banner_id"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Google Native Unit Id" vid="g_native_unit_id" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                                Google Native Unit Id
                                                            </template>
                                                            <b-form-input
                                                                id="g_native_unit_id"
                                                                v-model="update.g_native_unit_id"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                <b-col lg="6" sm="6">
                                                    <ValidationProvider name="Google Open Ads ID" vid="g_open_ads_id" rules="required">
                                                        <b-form-group
                                                        class="row"
                                                        label-cols-sm="12"
                                                        label-for="formData"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            Google Open Ads ID
                                                        </template>
                                                        <b-form-input
                                                            id="g_open_ads_id"
                                                            v-model="update.g_open_ads_id"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>

                                                
                                                <b-col lg="12" sm="12">
                                                    <div>
                                                        <p class="m-0 mb-2" style="font-weight: bold"> Active Facebook Ads </p>
                                                        <b-form-checkbox v-model="update.active_facebook_ads" size="md" name="check-button" switch>
                                                            <span v-if="update.active_facebook_ads">ON</span>
                                                            <span v-else>OF</span>
                                                        </b-form-checkbox>
                                                    </div>
                                                </b-col>
                                                <b-col lg="6" sm="6">
                                                    <ValidationProvider name="Facebook Interstitial ID" vid="f_interstitial_id" rules="required">
                                                        <b-form-group
                                                        class="row"
                                                        label-cols-sm="12"
                                                        label-for="formData"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            Facebook Interstitial ID
                                                        </template>
                                                        <b-form-input
                                                            id="f_interstitial_id"
                                                            v-model="update.f_interstitial_id"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col lg="6" sm="6">
                                                    <ValidationProvider name="Facebook Banner ID" vid="f_banner_id" rules="required">
                                                        <b-form-group
                                                        class="row"
                                                        label-cols-sm="12"
                                                        label-for="formData"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            Facebook Banner ID
                                                        </template>
                                                        <b-form-input
                                                            id="f_banner_id"
                                                            v-model="update.f_banner_id"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col lg="6" sm="6">
                                                    <ValidationProvider name="Facebook Rewarded Video Ads" vid="f_rewarded_video_ads" rules="required">
                                                        <b-form-group
                                                        class="row"
                                                        label-cols-sm="12"
                                                        label-for="formData"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            Facebook Rewarded Video Ads
                                                        </template>
                                                        <b-form-input
                                                            id="f_rewarded_video_ads"
                                                            v-model="update.f_rewarded_video_ads"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col lg="6" sm="6">
                                                    <ValidationProvider name="Facebook Native Unit ID" vid="f_native_unit_id" rules="required">
                                                        <b-form-group
                                                        class="row"
                                                        label-cols-sm="12"
                                                        label-for="formData"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            Facebook Native Unit ID
                                                        </template>
                                                        <b-form-input
                                                            id="f_native_unit_id"
                                                            v-model="update.f_native_unit_id"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                            </div>
                                            <div class="text-right">
                                                <b-button type="submit" variant="primary" style="margin-top:40px" class="mr-2">Update</b-button>
                                            </div>
                                        </b-form>
                                    </ValidationObserver>
                                </b-col>                                
                            </div>
                        </CCardBody>
                    </CCard>
                </b-col>
                <!-- <b-col lg="12" sm="12">
                    <CCard>
                        <CCardHeader>
                            <div class="row">
                                <div class="col-md-6">
                                    <strong>Notice Setting</strong>
                                </div>
                            </div>
                        </CCardHeader>
                        <CCardBody>
                            <div class='row'>
                                <b-col lg="12" sm="12">
                                    <ValidationObserver ref="form2"  v-slot="{ handleSubmit, reset }">
                                        <b-form  @submit.prevent="handleSubmit(register(setting))" @reset.prevent="reset" >
                                            <div class='row'>
                                                
                                                <b-col lg="6" sm="6">
                                                    <ValidationProvider name="Main Notice Box" vid="notice" rules="required">
                                                        <b-form-group
                                                        class="row"
                                                        label-cols-sm="12"
                                                        label-for="formData"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                        Main Notice Box
                                                        </template>
                                                        <b-form-textarea
                                                            type="number"
                                                            id="notice"
                                                            v-model="setting.notice"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-textarea>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col lg="6" sm="6">
                                                        <ValidationProvider name="Google Rewarded Video Ads" vid="maintance_notice" rules="required">
                                                            <b-form-group
                                                            class="row"
                                                            label-cols-sm="12"
                                                            label-for="formData"
                                                            slot-scope="{ valid, errors }"
                                                            >
                                                            <template v-slot:label>
                                                                Maintance Notite 
                                                            </template>
                                                            <b-form-input
                                                                id="maintance_notice"
                                                                v-model="update.maintance_notice"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                                ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                            </div>
                                            <div class="text-right">
                                                <b-button type="submit" variant="primary" style="margin-top:40px" class="mr-2">Update</b-button>
                                            </div>
                                        </b-form>
                                    </ValidationObserver>
                                </b-col>                                
                            </div>
                        </CCardBody>
                    </CCard>
                </b-col> -->
            </b-row>
        </b-overlay>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import RestApi, { baseUrl } from '../../config/api_config'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import iziToast from 'izitoast';

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
	this.getData()
  },
  data () {
    return {
      update: {
        maintance: false,
        version: '',
        dlink: ''
      },
      setting: {
        notice: '',
        convert_notice: '',
        payment_notich: ''
      },
      editId: ''
    }
  },
  computed: {
	loading () {
		return this.$store.state.static.loading
	}
  },
  methods: {
    getData () {
        this.$store.dispatch('mutedLoad', { loading: true})
        RestApi.getData(baseUrl, 'api/app-update/show').then(response => {
            if (response.success) {
                    this.update = response.data
                    this.update.maintance= response.data.maintance == '1' ? true : false,
                    this.update.promotion_on_off= response.data.promotion_on_off == '1' ? true : false,
                    this.update.app_on_off= response.data.app_on_off == '1' ? true : false,
                    this.update.active_facebook_ads= response.data.active_facebook_ads == '1' ? true : false,
                    this.update.active_google_ads= response.data.active_google_ads == '1' ? true : false,
                    this.update.telegram= response.data.telegram ,
                    this.update.maintance_notice= response.data.telegram ,
                    this.update.version= response.data.version,
                    this.update.dlink= response.data.dlink,
                    this.update.admin_dlink= response.data.admin_dlink,
                this.setting = {
                    notice: response.data.notice,
                    convert_notice: response.data.convert_notice,
                    payment_notich: response.data.payment_notich
                }
            }
            this.$store.dispatch('mutedLoad', { loading: false })
        })
    },
    async register (Obj) {
        this.$store.dispatch('mutedLoad', { loading: true })
        let result = null
        result = await RestApi.postData(baseUrl, `api/app-update/update`, Obj)
        this.$store.dispatch('mutedLoad', { loading: false })
        if (result.success) {
			iziToast.success({
				title: 'Success',
				message: result.message
			})
        } else {
            this.$refs.form.setErrors(result.errors)
        }
    }
  }
}
</script>
