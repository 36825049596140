var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{attrs:{"lg":"12","sm":"12"}},[_c('b-overlay',{attrs:{"show":_vm.loading}},[_c('b-row',[_c('b-col',{attrs:{"lg":"12","sm":"12"}},[_c('CCard',[_c('CCardHeader',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('strong',[_vm._v("App Update Setting")])])])]),_c('CCardBody',[_c('div',{staticClass:"row"},[_c('b-col',{attrs:{"lg":"12","sm":"12"}},[_c('ValidationObserver',{ref:"form1",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.register(_vm.update))},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('b-col',{attrs:{"lg":"3","sm":"3"}},[_c('div',[_c('p',{staticClass:"m-0 mb-2",staticStyle:{"font-weight":"bold"}},[_vm._v(" Maintance Mode ")]),_c('b-form-checkbox',{attrs:{"size":"md","name":"check-button","switch":""},model:{value:(_vm.update.maintance),callback:function ($$v) {_vm.$set(_vm.update, "maintance", $$v)},expression:"update.maintance"}},[(_vm.update.maintance)?_c('span',[_vm._v("ON")]):_c('span',[_vm._v("OF")])])],1)]),_c('b-col',{attrs:{"lg":"3","sm":"3"}},[_c('div',[_c('p',{staticClass:"m-0 mb-2",staticStyle:{"font-weight":"bold"}},[_vm._v(" Promotion ON OFF ")]),_c('b-form-checkbox',{attrs:{"size":"md","name":"check-button","switch":""},model:{value:(_vm.update.promotion_on_off),callback:function ($$v) {_vm.$set(_vm.update, "promotion_on_off", $$v)},expression:"update.promotion_on_off"}},[(_vm.update.promotion_on_off)?_c('span',[_vm._v("ON")]):_c('span',[_vm._v("OF")])])],1)]),_c('b-col',{attrs:{"lg":"3","sm":"3"}},[_c('div',[_c('p',{staticClass:"m-0 mb-2",staticStyle:{"font-weight":"bold"}},[_vm._v(" App ON OFF ")]),_c('b-form-checkbox',{attrs:{"size":"md","name":"check-button","switch":""},model:{value:(_vm.update.app_on_off),callback:function ($$v) {_vm.$set(_vm.update, "app_on_off", $$v)},expression:"update.app_on_off"}},[(_vm.update.app_on_off)?_c('span',[_vm._v("ON")]):_c('span',[_vm._v("OF")])])],1)]),_c('b-col',{attrs:{"lg":"6","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"App Download link","vid":"dlink","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" App Download link ")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"dlink","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.update.dlink),callback:function ($$v) {_vm.$set(_vm.update, "dlink", $$v)},expression:"update.dlink"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"lg":"6","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"App Download link 2","vid":"admin_dlink","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" App Download link 2 ")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"admin_dlink","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.update.admin_dlink),callback:function ($$v) {_vm.$set(_vm.update, "admin_dlink", $$v)},expression:"update.admin_dlink"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"lg":"6","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Main App Version","vid":"version","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Main App Version ")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"version","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.update.version),callback:function ($$v) {_vm.$set(_vm.update, "version", $$v)},expression:"update.version"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"lg":"6","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Telegram Link","vid":"telegram","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Telegram Link ")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"telegram","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.update.telegram),callback:function ($$v) {_vm.$set(_vm.update, "telegram", $$v)},expression:"update.telegram"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1)],1),_c('div',{staticClass:"text-right"},[_c('b-button',{staticClass:"mr-2",staticStyle:{"margin-top":"40px"},attrs:{"type":"submit","variant":"primary"}},[_vm._v("Update")])],1)])]}}])})],1)],1)])],1)],1),_c('b-col',{attrs:{"lg":"12","sm":"12"}},[_c('CCard',[_c('CCardHeader',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('strong',[_vm._v("Ads Setting")])])])]),_c('CCardBody',[_c('div',{staticClass:"row"},[_c('b-col',{attrs:{"lg":"12","sm":"12"}},[_c('ValidationObserver',{ref:"form2",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.register(_vm.update))},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('b-col',{attrs:{"lg":"12","sm":"12"}},[_c('div',[_c('p',{staticClass:"m-0 mb-2",staticStyle:{"font-weight":"bold"}},[_vm._v(" Active Google Ads ")]),_c('b-form-checkbox',{attrs:{"size":"md","name":"check-button","switch":""},model:{value:(_vm.update.active_google_ads),callback:function ($$v) {_vm.$set(_vm.update, "active_google_ads", $$v)},expression:"update.active_google_ads"}},[(_vm.update.active_google_ads)?_c('span',[_vm._v("ON")]):_c('span',[_vm._v("OF")])])],1)]),_c('b-col',{attrs:{"lg":"6","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Google Ads App ID","vid":"g_ads_app_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Google Ads App ID ")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"g_ads_app_id","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.update.g_ads_app_id),callback:function ($$v) {_vm.$set(_vm.update, "g_ads_app_id", $$v)},expression:"update.g_ads_app_id"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"lg":"6","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Google Rewarded Video Ads","vid":"g_rewarded_video_ads","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Google Rewarded Video Ads ")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"g_rewarded_video_ads","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.update.g_rewarded_video_ads),callback:function ($$v) {_vm.$set(_vm.update, "g_rewarded_video_ads", $$v)},expression:"update.g_rewarded_video_ads"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"lg":"6","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Google Interstitial Id","vid":"g_interstitial_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Google Interstitial Id ")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"g_interstitial_id","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.update.g_interstitial_id),callback:function ($$v) {_vm.$set(_vm.update, "g_interstitial_id", $$v)},expression:"update.g_interstitial_id"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"lg":"6","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Google Banner ID","vid":"g_banner_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Google Banner ID ")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"g_banner_id","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.update.g_banner_id),callback:function ($$v) {_vm.$set(_vm.update, "g_banner_id", $$v)},expression:"update.g_banner_id"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"lg":"6","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Google Native Unit Id","vid":"g_native_unit_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Google Native Unit Id ")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"g_native_unit_id","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.update.g_native_unit_id),callback:function ($$v) {_vm.$set(_vm.update, "g_native_unit_id", $$v)},expression:"update.g_native_unit_id"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"lg":"6","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Google Open Ads ID","vid":"g_open_ads_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Google Open Ads ID ")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"g_open_ads_id","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.update.g_open_ads_id),callback:function ($$v) {_vm.$set(_vm.update, "g_open_ads_id", $$v)},expression:"update.g_open_ads_id"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"lg":"12","sm":"12"}},[_c('div',[_c('p',{staticClass:"m-0 mb-2",staticStyle:{"font-weight":"bold"}},[_vm._v(" Active Facebook Ads ")]),_c('b-form-checkbox',{attrs:{"size":"md","name":"check-button","switch":""},model:{value:(_vm.update.active_facebook_ads),callback:function ($$v) {_vm.$set(_vm.update, "active_facebook_ads", $$v)},expression:"update.active_facebook_ads"}},[(_vm.update.active_facebook_ads)?_c('span',[_vm._v("ON")]):_c('span',[_vm._v("OF")])])],1)]),_c('b-col',{attrs:{"lg":"6","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Facebook Interstitial ID","vid":"f_interstitial_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Facebook Interstitial ID ")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"f_interstitial_id","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.update.f_interstitial_id),callback:function ($$v) {_vm.$set(_vm.update, "f_interstitial_id", $$v)},expression:"update.f_interstitial_id"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"lg":"6","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Facebook Banner ID","vid":"f_banner_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Facebook Banner ID ")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"f_banner_id","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.update.f_banner_id),callback:function ($$v) {_vm.$set(_vm.update, "f_banner_id", $$v)},expression:"update.f_banner_id"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"lg":"6","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Facebook Rewarded Video Ads","vid":"f_rewarded_video_ads","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Facebook Rewarded Video Ads ")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"f_rewarded_video_ads","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.update.f_rewarded_video_ads),callback:function ($$v) {_vm.$set(_vm.update, "f_rewarded_video_ads", $$v)},expression:"update.f_rewarded_video_ads"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"lg":"6","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Facebook Native Unit ID","vid":"f_native_unit_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Facebook Native Unit ID ")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"f_native_unit_id","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.update.f_native_unit_id),callback:function ($$v) {_vm.$set(_vm.update, "f_native_unit_id", $$v)},expression:"update.f_native_unit_id"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1)],1),_c('div',{staticClass:"text-right"},[_c('b-button',{staticClass:"mr-2",staticStyle:{"margin-top":"40px"},attrs:{"type":"submit","variant":"primary"}},[_vm._v("Update")])],1)])]}}])})],1)],1)])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }